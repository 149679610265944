export const COMETCHAT_CONSTANTS = {
  APP_ID: "267879a77f4b29cd",
  REGION: "in",
  AUTH_KEY: "apikey",
  AUTH_TOKEN_URL: "https://demo-service.cometchat.io/auth-token",
};

// export const COMETCHAT_CONSTANTS = {
//   APP_ID: "268322bac29a8817",
//   REGION: "in",
//   AUTH_KEY: "apikey",
//   AUTH_TOKEN_URL: "http://localhost:3003/auth-token",
// };
